import { useQuery } from 'react-query';
import { userQueryKeys } from 'enums/UserQueryKeys.enum';
import { useAuth } from 'hooks/Auth/useAuth';
import { LocalStorage } from 'services/LocalStorage';
import { STALE_DATA_TIME, STORE_AUTH_TOKEN } from 'utils/constants';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { microsoftCompleteSignIn, UserData } from 'services/API/auth';
import { AppRoutes } from 'enums/Routes.enum';
import { toast } from 'react-toastify';
import { getAPIErrorText } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { APIError } from 'types/apiError';

export const useMicrosoftMe = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = LocalStorage.getItem(STORE_AUTH_TOKEN);
  const { userData, onChangeUserData, logOut } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');

  return useQuery<UserData, APIError>(
    userQueryKeys.userData,
    () => microsoftCompleteSignIn(location.search),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      enabled: !token && !userData && !!code,
      onSuccess({ token, user }: UserData) {
        if (token && user) {
          onChangeUserData(user);
          LocalStorage.setItem(STORE_AUTH_TOKEN, token);
          setSearchParams('');
          navigate(AppRoutes.Dashboard);
        }
      },
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
        logOut();
      },
    }
  );
};
