import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTenants } from 'hooks/Tenant/useTenants';
import { useAuth } from 'hooks/Auth/useAuth';
import { If } from 'components/If';
import { api } from 'services/API/client';
import { queryClient } from 'index';
import { overallStateQueryKeys } from 'enums/OverallStateQueryKeys.enum';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { userQueryKeys } from 'enums/UserQueryKeys.enum';
import { AppRoutes } from 'enums/Routes.enum';
import { EMPTY_STATE } from 'utils/constants';

export const TenantSwitcher: FC = () => {
  const { data: tenants } = useTenants();
  const { tenantId, onChangeTenantId } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDashboardPage = pathname === AppRoutes.Dashboard;
  const isAdministrationPage = pathname === AppRoutes.Administration;

  const changePageToDefault = () => {
    if (!(isDashboardPage || isAdministrationPage)) {
      navigate(AppRoutes.Dashboard);
    }
  };

  const handleChange = (tenantId: string) => {
    api.defaults.headers['X-Tenant-Id'] = tenantId;
    onChangeTenantId(tenantId);

    queryClient.resetQueries(userQueryKeys.users);
    queryClient.resetQueries(overallStateQueryKeys.stateData);
    queryClient.resetQueries(machinesQueryKeys.machines);
  };

  useEffect(() => {
    if (!tenantId && tenants?.length) {
      handleChange(tenants[0].id);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  return (
    <If condition={!!tenants}>
      <Select
        required
        labelId="tenant-select-label"
        id="tenant-id"
        value={tenantId || ''}
        onChange={(e) => {
          changePageToDefault();
          handleChange(e.target.value);
        }}
        MenuProps={{ disableScrollLock: true }}
        sx={{
          padding: '5px 5px 5px 10px',
          '.MuiInputBase-input': {
            padding: 0,
          },
        }}
      >
        {(tenants || []).map((tenant) => {
          return (
            <MenuItem key={tenant.id} value={tenant.id}>
              {tenant.label || EMPTY_STATE}
            </MenuItem>
          );
        })}
      </Select>
    </If>
  );
};
