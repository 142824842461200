import {
  addHours,
  addSeconds,
  differenceInSeconds,
  isAfter,
  subHours,
} from 'date-fns';

import { DateRangeType } from 'types/machine';
import { TimeRangeQuery } from 'services/API/machine';
import { DateRanges } from 'enums/DateRanges.enum';

const HALF_OF_WEEK_IN_HOURS = 84;
const HALF_OF_DAY_IN_HOURS = 12;

export const getTimeRangeQuery = (
  dateRange: DateRangeType,
  selectedDate: Date
): TimeRangeQuery => {
  // selectedDate could be selected as a future date
  // To prevent getting the future range without data, we count that "future period" and add it to the selected date range
  // f.e. now 17 Feb 19:50, a user selected in the date picker 17 Feb, so we couldn't show the whole 17 Feb, we shift and the period will be 16 Feb 19:50 - 17 Feb 19:50

  switch (dateRange) {
    case DateRanges.HOUR: {
      const isFuturePeriod = isAfter(selectedDate, new Date());
      const differenceByNow = differenceInSeconds(new Date(), selectedDate);

      // If and range in the future, we shift the range in the past
      const startDate = isFuturePeriod
        ? addSeconds(subHours(selectedDate, 1), differenceByNow)
        : subHours(selectedDate, 1);
      const endDate = isFuturePeriod
        ? addSeconds(selectedDate, differenceByNow)
        : selectedDate;

      return {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      };
    }
    case DateRanges.WEEK: {
      // Here we set a range, selectedDate should be in the middle of this range
      const start = subHours(selectedDate, HALF_OF_WEEK_IN_HOURS);
      const end = addHours(selectedDate, HALF_OF_WEEK_IN_HOURS);

      const isFuturePeriod = isAfter(end, new Date());
      const differenceByNow = differenceInSeconds(new Date(), end);

      // If and range in the future, we shift the range in the past
      const startDate = isFuturePeriod
        ? addSeconds(start, differenceByNow)
        : start;
      const endDate = isFuturePeriod ? addSeconds(end, differenceByNow) : end;

      return { start: startDate.toISOString(), end: endDate.toISOString() };
    }
    default: {
      // Here we set a range, selectedDate should be whole selected day
      const start = subHours(selectedDate, HALF_OF_DAY_IN_HOURS);
      const end = addHours(selectedDate, HALF_OF_DAY_IN_HOURS);

      const isFuturePeriod = isAfter(end, new Date());
      const differenceByNow = differenceInSeconds(new Date(), end);

      // If and range in the future, we shift the range in the past
      const startDate = isFuturePeriod
        ? addSeconds(start, differenceByNow)
        : start;
      const endDate = isFuturePeriod ? addSeconds(end, differenceByNow) : end;

      return { start: startDate.toISOString(), end: endDate.toISOString() };
    }
  }
};
