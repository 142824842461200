import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { overallStateQueryKeys } from 'enums/OverallStateQueryKeys.enum';
import { fetchCustomerTypes } from 'services/API/customerTypes';
import {
  fetchMachineOverallStateData,
  fetchOverallStateData,
} from 'services/API/overallState';
import { APIError } from 'types/apiError';
import { TotalOverallStateData } from 'types/state';
import {
  ONE_DAY_IN_MS,
  REFRESH_OVERALL_STATES_INTERVAL,
  STALE_DATA_TIME,
} from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';
import { TimeRangeQuery } from '../../services/API/machine';

export const useGetOverallStateData = ({
  machineId,
  timeRange,
}: {
  machineId?: string;
  timeRange?: TimeRangeQuery;
}) => {
  const { t } = useTranslation();

  const timeRangeQuery: TimeRangeQuery = timeRange || {
    start: new Date(Date.now() - ONE_DAY_IN_MS).toISOString(),
    end: new Date(Date.now()).toISOString(),
  };

  return useQuery<TotalOverallStateData[], APIError>(
    machineId
      ? overallStateQueryKeys.machineStateData({
          id: machineId,
          timeRange: timeRangeQuery,
        })
      : overallStateQueryKeys.stateData,
    async () => {
      const types = await fetchCustomerTypes();
      const values = machineId
        ? await fetchMachineOverallStateData(machineId, timeRangeQuery)
        : await fetchOverallStateData(timeRangeQuery);

      const result = types.map((type) => {
        const itemData = values?.find((item) => item.id === type.id);

        return { ...type, ...itemData };
      }) as TotalOverallStateData[];

      return [...result].sort((a, b) => b.value - a.value);
    },
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      refetchInterval: REFRESH_OVERALL_STATES_INTERVAL,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );
};
