import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
  const intervalRef = useRef<number | undefined>();
  const callbackRef = useRef<() => void>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      callbackRef.current();
    }, delay);

    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, [delay]);

  return intervalRef;
};
