import { isSameHour } from 'date-fns';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useInterval } from 'hooks/ChartData/useInterval';
import {
  REFRESH_GENERAL_OVERVIEW_DEFAULT,
  REFRESH_SENSOR_DATA_INTERVAL,
} from 'utils/constants';
import { DateRanges } from 'enums/DateRanges.enum';

export const useLiveUpdateChartOptions = () => {
  const { selectedDate, dateRange, setNewDate } = useChartCacheData();
  const liveUpdate = isSameHour(new Date(), selectedDate);
  const isHourView = dateRange === DateRanges.HOUR;

  const refetchInterval = isHourView
    ? REFRESH_SENSOR_DATA_INTERVAL
    : REFRESH_GENERAL_OVERVIEW_DEFAULT;

  useInterval(() => {
    if (liveUpdate && isHourView) {
      setNewDate(new Date());
    }
  }, refetchInterval);
};
