export enum AppRoutes {
  Root = '/',
  Login = '/login',
  SignUp = '/sign-up',
  Dashboard = '/dashboard',
  Detail = '/detail/:id',
  Sensor = '/sensor/:id',
  ModelsList = '/models',
  Administration = '/administration',
}
