import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { differenceInMinutes } from 'date-fns';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { GeneralOverviewChart } from 'views/ChartView/GeneralOverviewChart';
import { SensorChart } from 'views/ChartView/SensorChart';
import { SensorSensitivitySettings } from 'views/ChartView/SensorChart/SensorSensivitySettings';

import { PermissionsGate } from 'components/PermissionsGate';

import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';
import { DateRanges } from 'enums/DateRanges.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import { fetchSensorData } from 'services/API/sensor';
import { FetchFilterProps } from 'services/API/machine';

import {
  getAPIErrorText,
  getCustomTimeRangeQuery,
  getTimeRangeQuery,
} from 'utils/helpers';
import { STALE_DATA_TIME } from 'utils/constants';

import { SensorReadingResponse } from 'types/sensor';
import { APIError } from 'types/apiError';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useLiveUpdateChartOptions } from 'hooks/ChartData/useLiveUpdateChartOptions';

export const SensorPage: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [openedSensors, setOpenedSensors] = useState<string[]>([]);
  const [sensorDataSet, setSensorDataSet] =
    useState<SensorReadingResponse | null>(null);
  const { selectedDate, dateRange, customStartDate, customEndDate } =
    useChartCacheData();
  useLiveUpdateChartOptions();

  const toggleIsOpen = (key: string) => {
    const isOpen = openedSensors.includes(key);
    setOpenedSensors((prev) =>
      isOpen ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };

  const options: FetchFilterProps = {
    timeRangeQuery:
      DateRanges.CUSTOM === dateRange
        ? getCustomTimeRangeQuery(customStartDate, customEndDate)
        : getTimeRangeQuery(dateRange, selectedDate),
    id,
  };

  const defaultOptions: FetchFilterProps = {
    timeRangeQuery: getTimeRangeQuery(DateRanges.HOUR, selectedDate),
    id,
  };

  const { data } = useQuery<SensorReadingResponse, APIError>(
    sensorQueryKeys.filteredSensorData(defaultOptions),
    () => fetchSensorData(defaultOptions),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  useEffect(() => {
    if (data) {
      setSensorDataSet(data);
    }
  }, [data]);

  const liveUpdate = differenceInMinutes(new Date(), selectedDate) < 60;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <GeneralOverviewChart
        options={options}
        liveUpdate={liveUpdate}
        additionalSettings={
          <PermissionsGate scopes={[UserScopes.UpdateSensitivity]}>
            <SensorSensitivitySettings id={id || ''} />
          </PermissionsGate>
        }
        isSensorPage
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {sensorDataSet ? (
          Object.keys(sensorDataSet).map((key) => (
            <SensorChart
              isOpen={openedSensors.includes(key)}
              toggleIsOpen={() => toggleIsOpen(key)}
              dataKey={key}
              key={`${sensorDataSet[key].order}${sensorDataSet[key].title}`}
              options={options}
              start={new Date(selectedDate.toISOString()).getTime()}
              liveUpdate={liveUpdate}
            />
          ))
        ) : (
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress color="secondary" size={70} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
