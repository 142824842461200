import * as queryString from 'query-string';

import {
  SensorReadingResponse,
  SensorSensitivityResponse,
  SensorSensitivityUpdateFormData,
} from 'types/sensor';

import { api } from './client';
import { TimeRangeQuery } from './machine';

export interface FetchSensorDataProps {
  timeRangeQuery: TimeRangeQuery;
  id?: string;
  dataKey?: string;
}

export const fetchSensorData = async (
  props: FetchSensorDataProps
): Promise<SensorReadingResponse> => {
  const { timeRangeQuery, id } = props;
  const query = queryString.stringify(timeRangeQuery);

  if (!id) return {};

  const {
    data: { data },
  } = await api.get(`/machines/${id}/readings?${query}`);

  return data;
};

export const fetchSensorSensitivityData = async (
  id: string
): Promise<SensorSensitivityResponse> => {
  const {
    data: { data },
  } = await api.get(`/models/${id}/details`);

  return data;
};

export const updateSensorSensitivity = async ({
  id,
  sensitivity,
}: SensorSensitivityUpdateFormData): Promise<SensorSensitivityResponse> => {
  const {
    data: { data },
  } = await api.put(`/models/${id}`, {
    sensitivity,
  });

  return data;
};
