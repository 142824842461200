import React, { FC } from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { CustomXAxisTick } from 'components/CustomXAxisTick';
import { DateRangeType } from 'types/machine';

const TICK_COUNT = 12;

const timeSteps = {
  hour: 5 * 60 * 1000, // 5 minutes
  day: 2 * 60 * 60 * 1000, // 2 hours
  week: 8 * 60 * 60 * 1000, // 8 hours
} as { [key in DateRangeType]: number };

const generateData = (start: number, dateRange: DateRangeType) => {
  const data = [{ name: start }];
  for (let i = 1; i <= TICK_COUNT; i += 1) {
    data.push({ name: start + i * timeSteps[dateRange] });
  }
  return data;
};

export const BasicChart: FC<{
  start: number;
  dateRange: DateRangeType;
}> = ({ start, dateRange }) => (
  <ResponsiveContainer width="100%" height={400}>
    <LineChart
      data={generateData(start, dateRange)}
      margin={{
        top: 30,
        right: 40,
        left: 40,
        bottom: 40,
      }}
    >
      <CartesianGrid />
      <XAxis
        dataKey="name"
        tick={<CustomXAxisTick />}
        interval="preserveStart"
        axisLine={false}
        tickLine={false}
      />
      <YAxis domain={[0, 100]} axisLine={false} tickLine={false} />
    </LineChart>
  </ResponsiveContainer>
);
