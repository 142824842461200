import React, { FC, memo } from 'react';
import { TablePagination } from '@mui/material';
import { BasicTable } from 'components/BasicTable';
import { useUsers } from 'hooks/Users/useUsers';

import { columns } from './columns';

export const UsersTable: FC = memo(() => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, isLoading } = useUsers({
    limit: rowsPerPage,
    offset: rowsPerPage * page,
  });

  return (
    <BasicTable
      columns={columns}
      data={data?.data || []}
      pagination={
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          count={data?.meta.count || 0}
          component="div"
          rowsPerPage={rowsPerPage}
          page={isLoading ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    />
  );
});
