import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import Box from '@mui/material/Box';

import { MAX_Y_AXIS, MIN_Y_AXIS, STALE_DATA_TIME } from 'utils/constants';
import {
  getAPIErrorText,
  getCustomTimeRangeQuery,
  getIsIntervalMoreTwoWeeks,
  getTimeRangeQuery,
} from 'utils/helpers';

import {
  FetchFilterProps,
  fetchMachineEvents,
  fetchMachineHealth,
} from 'services/API/machine';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { DateRanges } from 'enums/DateRanges.enum';

import { useGeneralChartData } from 'hooks/ChartData/useGeneralChartData';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';

import { APIError } from 'types/apiError';
import { MachineHealthData } from 'types/machine';
import { CustomerEvent } from 'types/event';

import { theme } from 'styles/theme';
import { CustomXAxisTick } from 'components/CustomXAxisTick';
import { ChartTooltip } from '../ChartTooltip';
import { ChartCursor } from '../ChartCursor';

type TableLightChartProps = {
  itemId?: string;
  isShowTicks?: boolean;
};

export const DetailTableChart: FC<TableLightChartProps> = ({
  itemId,
  isShowTicks,
}) => {
  const { t } = useTranslation();
  const { selectedDate, dateRange, customStartDate, customEndDate } =
    useChartCacheData();

  const options: FetchFilterProps = useMemo(
    () => ({
      timeRangeQuery:
        DateRanges.CUSTOM === dateRange
          ? getCustomTimeRangeQuery(customStartDate, customEndDate)
          : getTimeRangeQuery(dateRange, selectedDate),
      id: itemId || '',
    }),
    [dateRange, itemId, selectedDate, customStartDate, customEndDate]
  );

  const isLongDateFormat =
    dateRange === DateRanges.CUSTOM &&
    getIsIntervalMoreTwoWeeks(customStartDate, customEndDate);

  const [healthData, setHealthStatusData] = useState<MachineHealthData[]>([]);

  const { data: healthStatusData } = useQuery<MachineHealthData[], APIError>(
    machinesQueryKeys.filteredMachineHealth(options),
    () => fetchMachineHealth(options),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      enabled: !!itemId,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  useEffect(() => {
    if (healthStatusData) {
      setHealthStatusData(healthStatusData);
    }
  }, [healthStatusData]);

  const { data: eventsData } = useQuery<CustomerEvent[], APIError>(
    machinesQueryKeys.filteredMachineEvents(options),
    () => fetchMachineEvents(options),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      enabled: !!itemId,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  const renderCustomTooltip = useCallback(
    ({ payload: payloads }: TooltipProps<string, string>) => {
      if (!payloads?.length) {
        return null;
      }

      const { showTooltip } = payloads[0].payload;

      return showTooltip ? (
        <ChartTooltip
          payloads={payloads}
          valueFormatter={(value) =>
            value === 'NaN' ? t('emptyMessage.default') : `${value || 0}%`
          }
        />
      ) : null;
    },
    [t]
  );

  const generalChartData = useGeneralChartData(
    dateRange,
    healthData,
    eventsData || []
  );

  return (
    <Box sx={{ marginLeft: '-50px' }}>
      <ResponsiveContainer
        width="100%"
        height={isShowTicks ? 98 : 64}
        maxHeight={64}
      >
        <ComposedChart
          syncId="syncId"
          data={generalChartData}
          margin={{
            top: 1,
            right: 40,
            left: 0,
            bottom: isShowTicks ? 4 : 0,
          }}
        >
          <XAxis
            hide={!isShowTicks}
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval="preserveStart"
            tick={<CustomXAxisTick isLongDateFormat={isLongDateFormat} />}
            tickCount={24}
            type="number"
            scale="time"
            domain={['auto', 'auto']}
            tickSize={-5}
          />
          <YAxis
            dataKey="health"
            axisLine={false}
            tickLine={false}
            domain={[MIN_Y_AXIS, MAX_Y_AXIS]}
            tick={false}
          />
          <CartesianGrid opacity={0.7} horizontal={false} />
          {eventsData?.map((item) => {
            return (
              <Area
                type="step"
                dataKey={item.id}
                fill={item.type.hexcolor}
                fillOpacity={0.1}
                strokeWidth={0}
                activeDot={false}
                key={item.id}
                id={item.id}
                isAnimationActive={false}
              />
            );
          })}
          <Line
            type="linear"
            dataKey="health"
            stroke={theme.lightBlue}
            fillOpacity={0}
            strokeWidth={2}
            activeDot={false}
            dot={false}
            isAnimationActive={false}
          />
          <Tooltip
            content={renderCustomTooltip}
            isAnimationActive={false}
            cursor={<ChartCursor />}
            position={{ y: 20 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
