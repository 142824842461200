import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, TablePagination } from '@mui/material';

import { If } from 'components/If';
import { BasicTable } from 'components/BasicTable';
import { EmptyMessage } from 'components/EmptyMessage/EmptyMessage';

import { AppRoutes } from 'enums/Routes.enum';
import { detailsQueryKeys } from 'enums/DetailsQueryKeys.enum';

import { useTableSorting } from 'hooks/Table/useTableSorting';

import { fetchDetails } from 'services/API/detail';
import { MachinesFullData } from 'services/API/machine';

import { APIError } from 'types/apiError';

import { STALE_DATA_TIME } from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';

import { columns } from './columns';

export const DetailsTable: FC = memo(() => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { sortingOrder, sortingType, onSetSortingType } = useTableSorting();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const options = {
    id: id || '',
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    sort: sortingType,
    order: sortingOrder,
  };

  const { data, isLoading } = useQuery<MachinesFullData, APIError>(
    detailsQueryKeys.filteredDetails(options),
    () => fetchDetails(options),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      enabled: !!id,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  return (
    <Box width="100%">
      <BasicTable
        columns={columns}
        data={data?.data || []}
        onClickByRow={(item) =>
          navigate(
            generatePath(item.is_sensor ? AppRoutes.Sensor : AppRoutes.Detail, {
              id: item.id,
            })
          )
        }
        onClickByColumnHead={onSetSortingType}
        sortingOrder={sortingOrder}
        sortingType={sortingType}
        pagination={
          !!data?.meta.count && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              count={data?.meta.count || 0}
              component="div"
              rowsPerPage={rowsPerPage}
              page={isLoading ? 0 : page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                marginTop: '32px',
                borderTop: '1px solid',
                borderColor: (theme) => theme.palette.custom.borderColor,
              }}
            />
          )
        }
      />
      <If condition={!isLoading && !data?.data.length}>
        <EmptyMessage message={t('emptyMessage.details')} />
      </If>
    </Box>
  );
});
