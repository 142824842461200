import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addDays,
  addHours,
  addMonths,
  set,
  setHours,
  subMonths,
} from 'date-fns';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Button, IconButton, SegmentedControl } from '@radix-ui/themes';

import { DataPicker } from 'components/DataPicker';
import { If } from 'components/If';
import { CalendarHeader } from 'components/CalendarHeader';
import { CalendarLayout } from 'components/CalendarLayout';

import {
  getIsCurrentPeriod,
  getPrevRange,
  getTimeRangeQuery,
  getUTCDateForPost,
} from 'utils/helpers';

import { DateRangeType } from 'types/machine';
import { DateRanges } from 'enums/DateRanges.enum';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { Languages } from 'enums/Languages.enum';

export const ChartControl: FC = () => {
  const { t, i18n } = useTranslation();
  const {
    selectedDate,
    dateRange,
    setNewDateRange: setDateRange,
    setNewDate,
    setCustomStartDateRange,
    setCustomEndDateRange,
    customStartDate,
    customEndDate,
  } = useChartCacheData();

  const hourView = dateRange === DateRanges.HOUR;
  const isCurrentPeriod = getIsCurrentPeriod({
    dateRange,
    selectedDate,
  });

  const prevRange = () => {
    // minus 1 day for the 'day' dateRange type and 7 days for the 'week'
    setNewDate(getPrevRange({ dateRange, selectedDate, isCurrentPeriod }));
  };

  const nextRange = () => {
    if (hourView) {
      setNewDate(addHours(selectedDate, 1));
    } else {
      // plus 1 day for the 'day' dateRange type and 7 days for the 'week'
      setNewDate(addDays(selectedDate, dateRange === DateRanges.DAY ? 1 : 7));
    }
  };

  const setNewDateRange = (range: DateRangeType): void => {
    const newDate = new Date(selectedDate);
    setNewDate(new Date(newDate));

    setDateRange(range);
  };

  const onSelectCustomRange = (): void => {
    const { start, end } = getTimeRangeQuery(dateRange, selectedDate);
    setNewDateRange(DateRanges.CUSTOM);
    setCustomStartDateRange(start ? new Date(start) : null);
    setCustomEndDateRange(end ? new Date(end) : null);
  };

  const handleChange = (value: DateRanges): void => {
    setNewDateRange(value);
    if (value === DateRanges.CUSTOM) {
      return onSelectCustomRange();
    }
    if (!isCurrentPeriod) setNewDate(setHours(selectedDate, 12));
  };

  const onChangeDate = (date: Date): void => {
    // We set the hour as 13 because in the "hour view" we get the previous hour
    const newDate = set(date, {
      hours: dateRange === DateRanges.HOUR ? 13 : 12,
      minutes: 0,
      seconds: 0,
    });
    setNewDate(newDate);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <If condition={dateRange === DateRanges.CUSTOM}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            mr: '16px',
          }}
        >
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500 }}
            color="secondary"
          >
            {t('charts.from')}
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={i18n.language === Languages.EN ? enLocale : deLocale}
          >
            <DateTimePicker
              ampm={false}
              value={customStartDate}
              onChange={() => {}}
              onAccept={(value) =>
                setCustomStartDateRange(value || getUTCDateForPost(new Date()))
              }
              format="d LLL yyyy   HH:mm"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                },
                '& .MuiOutlinedInput-input': {
                  p: '4px 0 5px 14px',
                },
              }}
              minDateTime={subMonths(
                customEndDate ? new Date(customEndDate) : new Date(),
                2
              )}
              maxDateTime={customEndDate ? new Date(customEndDate) : undefined}
              slots={{
                calendarHeader: CalendarHeader,
                layout: CalendarLayout,
              }}
            />
            <Typography
              sx={{ pl: '8px', fontSize: '14px', fontWeight: 500 }}
              color="secondary"
            >
              {t('charts.to')}
            </Typography>
            <DateTimePicker
              ampm={false}
              value={customEndDate}
              onChange={() => {}}
              onAccept={(value) =>
                setCustomEndDateRange(value || getUTCDateForPost(new Date()))
              }
              minDateTime={
                customStartDate ? new Date(customStartDate) : undefined
              }
              maxDateTime={addMonths(
                customStartDate ? new Date(customStartDate) : new Date(),
                2
              )}
              format="d LLL yyyy   HH:mm"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                },
                '& .MuiOutlinedInput-input': { p: '4px 0 5px 14px' },
              }}
              slots={{
                calendarHeader: CalendarHeader,
                layout: CalendarLayout,
              }}
              disableFuture
            />
          </LocalizationProvider>
        </Box>
      </If>

      <If condition={dateRange !== DateRanges.CUSTOM}>
        <Button
          color="gray"
          disabled={isCurrentPeriod}
          variant="outline"
          onClick={() => setNewDate(new Date())}
          mr="5"
        >
          {t(hourView ? 'charts.now' : 'charts.today')}
        </Button>
        <IconButton
          mr="2"
          aria-label="back"
          onClick={prevRange}
          variant="ghost"
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="forward"
          variant="ghost"
          disabled={isCurrentPeriod}
          onClick={nextRange}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
        <Box
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            margin: '0 30px 0 15px',
          }}
        >
          <DataPicker
            setNewDate={onChangeDate}
            value={selectedDate}
            labelStyles={{ fontSize: '14px', minWidth: '115px' }}
            maxDate={new Date()}
          />
        </Box>
      </If>
      <SegmentedControl.Root
        defaultValue={DateRanges.HOUR}
        onValueChange={handleChange}
      >
        <SegmentedControl.Item value={DateRanges.CUSTOM}>
          {t('charts.custom')}
        </SegmentedControl.Item>
        <SegmentedControl.Item value={DateRanges.WEEK}>
          {t('charts.week')}
        </SegmentedControl.Item>
        <SegmentedControl.Item value={DateRanges.DAY}>
          {t('charts.day')}
        </SegmentedControl.Item>
        <SegmentedControl.Item value={DateRanges.HOUR}>
          {t('charts.hour')}
        </SegmentedControl.Item>
      </SegmentedControl.Root>
    </Box>
  );
};
