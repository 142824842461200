import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';

import { BasicTable } from 'components/BasicTable';
import { EmptyMessage } from 'components/EmptyMessage/EmptyMessage';
import { If } from 'components/If';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { detailsQueryKeys } from 'enums/DetailsQueryKeys.enum';
import { AppRoutes } from 'enums/Routes.enum';

import { fetchDetails } from 'services/API/detail';
import { MachinesFullData, fetchMachines } from 'services/API/machine';

import { SortingOrder, SortingType } from 'hooks/Table/useTableSorting';

import {
  STALE_DATA_TIME,
  REFRESH_MACHINES_TABLE_INTERVAL,
} from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';

import { APIError } from 'types/apiError';

import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { MachineTree } from 'types/machine';
import { columns } from './columns';

const getChildrenById = (data: MachineTree[], id: string) => {
  let children: MachineTree[] = [];
  data.forEach((item) => {
    if (item.id === id) {
      children = item.children;
    } else if (item.children && !children.length) {
      children = getChildrenById(item.children, id);
    }
  });
  return children;
};

export const MostCriticalTable: FC<{ isMachinesList?: boolean }> = ({
  isMachinesList = false,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const options = {
    id: id || '',
    limit: 4,
    offset: 0,
    sort: SortingType.HEALTH,
    order: SortingOrder.ASC,
  };

  const { data: machineTree = [] } = useMachineTree();

  // fetch parts list
  const { data: partsList, isLoading: partsListIsLoading } = useQuery<
    MachinesFullData,
    APIError
  >(detailsQueryKeys.filteredDetails(options), () => fetchDetails(options), {
    retry: 0,
    refetchInterval: REFRESH_MACHINES_TABLE_INTERVAL,
    staleTime: STALE_DATA_TIME,
    enabled: !!id,
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  // fetch machines list
  const { data: machinesList, isLoading: machinesListIsLoading } = useQuery<
    MachinesFullData,
    APIError
  >(machinesQueryKeys.filteredMachines(options), () => fetchMachines(options), {
    retry: 0,
    refetchInterval: REFRESH_MACHINES_TABLE_INTERVAL,
    staleTime: STALE_DATA_TIME,
    enabled: isMachinesList,
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  let preloadedList = id
    ? getChildrenById(machineTree || [], id || '')
    : machineTree;
  preloadedList = preloadedList.slice(0, options.limit);

  const itemList = partsList?.data || machinesList?.data || preloadedList;

  return (
    <Box width="100%">
      <BasicTable
        columns={columns}
        data={itemList || []}
        onClickByRow={(item) =>
          navigate(
            generatePath(item.is_sensor ? AppRoutes.Sensor : AppRoutes.Detail, {
              id: item.id,
            })
          )
        }
        loading={machinesListIsLoading}
      />
      <If
        condition={
          (!partsListIsLoading && !partsList?.data.length && !isMachinesList) ||
          (!machinesListIsLoading &&
            !machinesList?.data.length &&
            isMachinesList)
        }
      >
        <EmptyMessage message={t('emptyMessage.details')} />
      </If>
    </Box>
  );
};
