import React from 'react';

import { Column } from 'components/BasicTable/BasicTable';
import { HealthScoreChip } from 'components/HealthScoreChip';
import { HealthBadge } from 'components/HealthBadge';

import { Detail } from 'types/detail';
import { Flex, Skeleton, Text } from '@radix-ui/themes';

export const columns: Column<Detail>[] = [
  {
    id: 'state',
    width: 200,
    label: 'tableHeader.mostCriticalHealth',
    format: ({ health }) => <HealthBadge health={health} />,
  },
  {
    id: 'name',
    label: 'tableHeader.label',
    format: ({ label }) => (
      <Text ml="2" size="3">
        {label}
      </Text>
    ),
  },
  {
    id: 'healthCore',
    label: 'tableHeader.healthScore',
    align: 'right',
    minWidth: 120,
    format: ({ health }) => (
      <Flex justify="end">
        <Skeleton loading={health === undefined}>
          <HealthScoreChip score={health || 0} />
        </Skeleton>
      </Flex>
    ),
  },
];
