import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { GeneralOverviewChart } from 'views/ChartView/GeneralOverviewChart';
import { DetailsTable } from 'views/Detail/DetailsTable';
import { DetailCriticalSensorWidgets } from 'views/Detail/DetailCriticalSensorWidgets';
import { OverallChartDateLabel } from 'views/ChartView/OverallChartDateLabel';
import { OverallStates } from 'views/Dashboard/OverallStates';

import { MostCriticalTable } from 'components/MostCriticalTable';

import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { FetchFilterProps } from 'services/API/machine';

import { getCustomTimeRangeQuery, getTimeRangeQuery } from 'utils/helpers';
import { useLiveUpdateChartOptions } from 'hooks/ChartData/useLiveUpdateChartOptions';
import { DateRanges } from 'enums/DateRanges.enum';

const boxStyles = { display: 'flex', gap: '8px', marginBottom: '24px' };

export const DetailPage: FC = () => {
  const { id } = useParams();
  const { selectedDate, dateRange, customStartDate, customEndDate } =
    useChartCacheData();

  const options: FetchFilterProps = {
    timeRangeQuery:
      DateRanges.CUSTOM === dateRange
        ? getCustomTimeRangeQuery(customStartDate, customEndDate)
        : getTimeRangeQuery(dateRange, selectedDate),
    id,
  };

  useLiveUpdateChartOptions();

  return (
    <>
      <Box sx={boxStyles}>
        <OverallStates machineId={id} timeRange={options.timeRangeQuery}>
          <OverallChartDateLabel options={options} />
        </OverallStates>
        <DetailCriticalSensorWidgets />
        <MostCriticalTable />
      </Box>
      <Box sx={boxStyles}>
        <GeneralOverviewChart options={options} />
      </Box>
      <Box sx={boxStyles}>
        <DetailsTable />
      </Box>
    </>
  );
};
