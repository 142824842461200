import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import {
  Card,
  IconButton,
  Text,
  Flex,
  Badge,
  Link,
  Dialog,
  BadgeProps,
} from '@radix-ui/themes';

import { If } from 'components/If';

import { useAuth } from 'hooks/Auth/useAuth';

import { CustomerEvent } from 'types/event';

import { getUTCDateForView } from 'utils/helpers';

import { ReactComponent as ComponentIcon } from 'assets/icons/detail-outlined.svg';

import { ArrowRightIcon, Pencil1Icon } from '@radix-ui/react-icons';

import { EventTypes, StateColors } from 'enums/EventTypes.enum';
import { AppRoutes } from 'enums/Routes.enum';
import { EventForm } from 'views/Event/EventForm';
import { DeleteMenu } from './DeleteMenu';

interface EventCardProps {
  event: CustomerEvent;
}

export const EventCard: FC<EventCardProps> = ({ event }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();

  const [isEditEventFormOpen, setIsEditEventFormOpen] = useState(false);

  const showControlButtons = event.user_id === userData?.id;

  const dateFormat = 'd. LLL HH:mm';
  const start = format(getUTCDateForView(event.start), dateFormat);
  const end = format(getUTCDateForView(event.end), dateFormat);

  return (
    <Card variant="ghost">
      <Flex justify="between" align="center">
        <Text weight="bold">{event.label}</Text>
        <If condition={showControlButtons}>
          <Flex gapX="1">
            <IconButton
              size="1"
              variant="ghost"
              onClick={() => setIsEditEventFormOpen(true)}
            >
              <Pencil1Icon />
            </IconButton>
            <DeleteMenu eventId={event.id} />
          </Flex>
        </If>
      </Flex>
      <Flex align="center" ml="-1">
        <RouterLink to={generatePath(AppRoutes.Detail, { id: event.part.id })}>
          <Flex align="center" gapX="1">
            <ComponentIcon height="17px" />
            <Link
              size="1"
              color="gray"
              mr="1"
              href={`/detail/${event.part.id}`}
            >
              {event.part.label}
            </Link>
          </Flex>
        </RouterLink>
        <ArrowRightIcon />
        <Badge
          size="1"
          color={
            StateColors[
              event.type.identifier || EventTypes.INACTIVE
            ] as BadgeProps['color']
          }
          ml="1"
        >
          {event.type.label}
        </Badge>
      </Flex>
      <Flex direction="column" gap="1" mt="1">
        <Text size="1" as="div">
          {event.description}
        </Text>
        <Text size="1" as="div">
          {start} - {end}
        </Text>
      </Flex>

      <Dialog.Root
        open={isEditEventFormOpen}
        onOpenChange={setIsEditEventFormOpen}
      >
        <Dialog.Content
          style={{
            maxWidth: '700px',
          }}
        >
          <Dialog.Title>{t('events.editEvent')}</Dialog.Title>
          <EventForm
            event={event}
            closeDialog={() => setIsEditEventFormOpen(false)}
          />
        </Dialog.Content>
      </Dialog.Root>
    </Card>
  );
};
