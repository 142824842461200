import { FetchFilterProps } from 'services/API/machine';

export const sensorQueryKeys = {
  sensor: ['sensor'],
  sensitivity: ['sensitivity'],
  filteredSensorData: (
    filters: FetchFilterProps
  ): (string | { filters: FetchFilterProps })[] => [
    ...sensorQueryKeys.sensor,
    { filters },
  ],
  sensitivityPath: (id: string): string[] => [
    ...sensorQueryKeys.sensor,
    ...sensorQueryKeys.sensitivity,
    id,
  ],
};
