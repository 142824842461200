import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { UserQueryFilters, userQueryKeys } from 'enums/UserQueryKeys.enum';
import { fetchUsers, UsersFullData } from 'services/API/user';
import { APIError } from 'types/apiError';
import { STALE_DATA_TIME } from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';

export const useUsers = (props: UserQueryFilters) => {
  const { t } = useTranslation();

  return useQuery<UsersFullData, APIError>(
    userQueryKeys.filteredUsers(props),
    () => fetchUsers(props),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );
};
