import { getChartEventsData } from 'utils/helpers';

import { CustomerEvent } from 'types/event';
import {
  DateRangeType,
  GeneralChartData,
  MachineHealthData,
} from 'types/machine';

export const useGeneralChartData = (
  dateRange: DateRangeType,
  healthStatusData?: MachineHealthData[],
  eventsData?: CustomerEvent[]
): GeneralChartData[] => {
  const preparedHealthData =
    healthStatusData?.map((item) => {
      return {
        ...item,
        showTooltip: true,
        // converting date from the ISO string to milliseconds
        date: new Date(item.date).getTime(),
      } as GeneralChartData;
    }) ?? [];

  const firstPointTime = preparedHealthData[0]?.date;

  const lastPointTime = preparedHealthData[preparedHealthData.length - 1]?.date;

  if (!eventsData || !firstPointTime || !lastPointTime) {
    return [];
  }

  return [
    ...preparedHealthData,
    ...getChartEventsData(firstPointTime, lastPointTime, eventsData),
  ];
};
