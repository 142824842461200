import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';

import { Flex, Inset, Separator, Spinner, Text } from '@radix-ui/themes';

import { If } from 'components/If';

import { CustomerEventsWithPagination, fetchEvents } from 'services/API/event';

import { CustomerEvent } from 'types/event';
import { APIError } from 'types/apiError';

import { eventsQueryKeys } from 'enums/EventsQueryKeys.enum';

import { STALE_DATA_TIME } from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';
import { format, parseISO } from 'date-fns';
import { EventCard } from 'components/EventCard/EventCard';

interface EventListProps {
  selectedDate: Date;
}

const showMonthTitle = (
  event: CustomerEvent,
  eventIndex: number,
  events?: CustomerEvent[]
): boolean => {
  if (!events) return false;
  const eventEnd = parseISO(event.end);
  const prevEvent = events[eventIndex - 1];
  const prevEventEnd = prevEvent && parseISO(prevEvent.end);

  return eventIndex === 0 || eventEnd.getMonth() !== prevEventEnd.getMonth();
};

export const EventList: FC<EventListProps> = ({ selectedDate }) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const [fetchError, setFetchError] = useState(false);

  const { data, fetchNextPage, refetch, isLoading, isFetchingNextPage } =
    useInfiniteQuery<CustomerEventsWithPagination, APIError>(
      eventsQueryKeys.infinityPaginatedEvents(selectedDate),
      ({ pageParam = selectedDate.toISOString() }) => fetchEvents(pageParam),
      {
        retry: false,
        staleTime: STALE_DATA_TIME,
        onError(error) {
          setFetchError(true);
          toast.error(getAPIErrorText(error) || t('errors.defaultError'));
        },
        getNextPageParam: (lastPage) => lastPage.nextStart,
      }
    );

  useEffect(() => {
    if (inView && !fetchError) fetchNextPage();
  }, [inView, fetchNextPage, fetchError]);

  useEffect(() => {
    refetch();
  }, [selectedDate, refetch]);

  const allEvents = data?.pages.reduce((acc, rec) => {
    return [...acc, ...rec.data];
  }, [] as CustomerEvent[]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
      }}
    >
      <If condition={isLoading}>
        <Flex p="2" align="center" justify="center">
          <Spinner />
        </Flex>
      </If>
      <If condition={!isLoading && !allEvents?.length}>
        <Flex p="2" align="center" justify="center">
          <Text size="2">{t('events.noEvents')}</Text>
        </Flex>
      </If>
      <If condition={!isLoading && !!allEvents?.length}>
        <Flex direction="column" gap="3" width="100%" mt="-4">
          {allEvents?.map((event, index) => (
            <>
              {showMonthTitle(event, index, allEvents) ? (
                <Inset side="x" key={`date-${event.id}`}>
                  <Flex style={{ background: 'var(--gray-3)' }} px="4" py="1">
                    <Text size="2" weight="medium">
                      {format(parseISO(event.end), 'LLLL yyyy')}
                    </Text>
                  </Flex>
                </Inset>
              ) : (
                <Separator size="4" key={`separator-${event.id}`} />
              )}
              <EventCard event={event} key={event.id} />
            </>
          ))}
        </Flex>
        <If condition={isFetchingNextPage}>
          <Flex p="2" align="center" justify="center">
            <Spinner />
          </Flex>
        </If>
        <Box ref={ref} />
      </If>
    </Box>
  );
};
