import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { differenceInDays, differenceInMinutes } from 'date-fns';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import Box from '@mui/material/Box';

import { ChartControl } from 'components/ChartControl';
import { CustomXAxisTick } from 'components/CustomXAxisTick';
import { ChartCustomLabel } from 'components/ChartCustomLabel';

import { getAPIErrorText, getIsIntervalMoreTwoWeeks } from 'utils/helpers';
import {
  MAX_Y_AXIS,
  MIN_Y_AXIS,
  REFRESH_GENERAL_OVERVIEW_DEFAULT,
  REFRESH_SENSOR_DATA_INTERVAL,
  STALE_DATA_TIME,
} from 'utils/constants';

import {
  FetchFilterProps,
  fetchMachineEvents,
  fetchMachineHealth,
} from 'services/API/machine';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';

import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useGeneralChartData } from 'hooks/ChartData/useGeneralChartData';
import { useNoDataEvents } from 'hooks/ChartData/useNoDataEvent';

import { MachineHealthData } from 'types/machine';
import { APIError } from 'types/apiError';
import { CustomerEvent } from 'types/event';

import { theme } from 'styles/theme';

import { DateRanges } from 'enums/DateRanges.enum';
import { ReferenceBand } from './ReferenceBand';
import { ChartTooltip } from '../ChartTooltip';
import { ChartCursor } from '../ChartCursor';

interface GeneralOverviewChartProps {
  options: FetchFilterProps;
  liveUpdate?: boolean;
  additionalSettings?: React.ReactNode;
  isSensorPage?: boolean;
}

export const filterEvents = (data?: CustomerEvent[]) => {
  const currTime = new Date();

  if (data) {
    return data.filter(
      (point) => differenceInMinutes(currTime, new Date(point.end)) < 60
    );
  }
  return [];
};

export const GeneralOverviewChart: FC<GeneralOverviewChartProps> = ({
  options,
  liveUpdate,
  additionalSettings,
  isSensorPage,
}) => {
  const { t } = useTranslation();
  const { dateRange, customEndDate, customStartDate } = useChartCacheData();
  const hourView = dateRange === DateRanges.HOUR;
  const [healthStatusData, setHealthStatusData] = useState<MachineHealthData[]>(
    []
  );

  const isLongDateFormat =
    dateRange === DateRanges.CUSTOM &&
    getIsIntervalMoreTwoWeeks(customStartDate, customEndDate);

  const isRangeDateMoreThanOneDay = useCallback(() => {
    if (dateRange === DateRanges.WEEK) {
      return true;
    }

    if (dateRange === DateRanges.CUSTOM && customEndDate && customStartDate) {
      return differenceInDays(customEndDate, customStartDate) > 1;
    }

    return false;
  }, [customEndDate, customStartDate, dateRange]);

  const refetchInterval = hourView
    ? REFRESH_SENSOR_DATA_INTERVAL
    : REFRESH_GENERAL_OVERVIEW_DEFAULT;

  const { data } = useQuery<MachineHealthData[], APIError>(
    machinesQueryKeys.filteredMachineHealth(options),
    () => fetchMachineHealth(options),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  const renderCustomTooltip = useCallback(
    ({ payload: payloads }: TooltipProps<string, string>) => {
      if (!payloads?.length) {
        return null;
      }

      const { date, showTooltip } = payloads[0].payload;

      return showTooltip ? (
        <ChartTooltip
          date={date}
          payloads={payloads}
          valueFormatter={(value) =>
            value === 'NaN' ? t('emptyMessage.default') : `${value || 0}%`
          }
          isRangeMoreOneDay={isRangeDateMoreThanOneDay()}
        />
      ) : null;
    },
    [isRangeDateMoreThanOneDay, t]
  );

  useEffect(() => {
    if (data) {
      setHealthStatusData(data);
    }
  }, [data]);

  const { data: eventsData = [] } = useQuery<CustomerEvent[], APIError>(
    machinesQueryKeys.filteredMachineEvents(options),
    () => fetchMachineEvents(options),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      refetchInterval,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );
  const noDataEvents = useNoDataEvents(healthStatusData || []);
  const events = [...noDataEvents, ...eventsData];

  const generalChartData = useGeneralChartData(
    dateRange,
    healthStatusData,
    liveUpdate && hourView ? filterEvents(events) : events
  );

  return (
    <Box
      sx={{
        border: '1px solid',
        width: '100%',
        height: 'fit-content',
        borderRadius: '4px',
        marginBottom: '10px',
        borderColor: (theme) => theme.palette.custom.borderColor,
        backgroundColor: (theme) => theme.palette.custom.white,
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '157%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderColor: (theme) => theme.palette.custom.borderColor,
        }}
      >
        <span style={{ minWidth: '90px' }}>
          {t('charts.generalOverviewTitle')}
        </span>
        <div style={{ display: 'flex', gap: '10px' }}>
          <ChartControl />
          {additionalSettings}
        </div>
      </Box>
      <ResponsiveContainer width="100%" height={320}>
        <ComposedChart
          syncId="syncId"
          data={generalChartData}
          margin={{
            top: 30,
            right: 40,
            left: isSensorPage ? 20 : 63,
            bottom: 30,
          }}
        >
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            padding={{ left: isSensorPage ? 10 : 15 }}
            interval="preserveStart"
            tick={<CustomXAxisTick isLongDateFormat={isLongDateFormat} />}
            tickCount={24}
            type="number"
            scale="time"
            domain={['auto', 'auto']}
          />
          <YAxis
            dataKey="health"
            axisLine={false}
            tickLine={false}
            tickCount={5}
            tickMargin={isSensorPage ? 0 : 10}
            domain={[MIN_Y_AXIS, MAX_Y_AXIS]}
          />
          {events?.map((item) => {
            return (
              <Area
                type="step"
                dataKey={item.id}
                fill={item.type.hexcolor}
                fillOpacity={0.1}
                strokeWidth={0}
                activeDot={false}
                key={item.id}
                id={item.id}
                label={<ChartCustomLabel maxValue={MAX_Y_AXIS} event={item} />}
                isAnimationActive={false}
              />
            );
          })}
          <CartesianGrid opacity={0.7} />
          <ReferenceArea
            shape={<ReferenceBand isSensorPage={isSensorPage} />}
          />
          <Line
            type="linear"
            dataKey="health"
            stroke={theme.lightBlue}
            fillOpacity={0}
            strokeWidth={2}
            activeDot={false}
            dot={false}
            isAnimationActive={false}
          />
          <Tooltip
            content={renderCustomTooltip}
            cursor={<ChartCursor />}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
