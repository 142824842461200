import React, { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { Dialog } from '@radix-ui/themes';

import { userQueryKeys } from 'enums/UserQueryKeys.enum';

import { deleteUser, DeleteUserProps } from 'services/API/user';

import { getAPIErrorText } from 'utils/helpers';

import { APIError } from 'types/apiError';
import { User } from 'types/user';

import { queryClient } from 'index';
import { UserForm } from 'views/Administration/UserForm';

interface UserMenuProps {
  user: User;
}

export const UserMenu: FC<UserMenuProps> = ({ user }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditUserFormOpen, setEditUserFormOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: deleteUserById } = useMutation<
    null,
    APIError,
    DeleteUserProps
  >(deleteUser, {
    onSuccess() {
      toast.success(t('toast.userDeleted'));
      queryClient.invalidateQueries(userQueryKeys.users);
    },
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  const onEdit = () => {
    setEditUserFormOpen(true);
    setAnchorEl(null);
  };

  const onDelete = () => {
    deleteUserById({ userId: user.id });
  };

  const menuItemStyles = {
    minWidth: '200px',
    svg: { marginRight: '16px' },
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon color="secondary" />
      </IconButton>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={onEdit}
          sx={{
            ...menuItemStyles,
            color: (theme) => theme.palette.custom.secondaryFontColor,
          }}
        >
          <EditIcon />
          {t('forms.editUser')}
        </MenuItem>
        <MenuItem
          onClick={onDelete}
          sx={{
            ...menuItemStyles,
            color: (theme) => theme.palette.error.main,
          }}
        >
          <DeleteIcon color="error" />
          {t('forms.deleteUser')}
        </MenuItem>
      </Menu>
      <Dialog.Root open={isEditUserFormOpen} onOpenChange={setEditUserFormOpen}>
        <Dialog.Content
          style={{
            maxWidth: '700px',
          }}
        >
          <Dialog.Title>{t('administration.editUser')}</Dialog.Title>
          <UserForm
            user={user}
            closeDialog={() => setEditUserFormOpen(false)}
          />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
