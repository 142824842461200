import { useQuery } from 'react-query';
import { userQueryKeys } from 'enums/UserQueryKeys.enum';
import { useAuth } from 'hooks/Auth/useAuth';
import { loadMe } from 'services/API/user';
import { LocalStorage } from 'services/LocalStorage';
import { User } from 'types/user';
import { STALE_DATA_TIME, STORE_AUTH_TOKEN } from 'utils/constants';

export const useMe = () => {
  const token = LocalStorage.getItem(STORE_AUTH_TOKEN);
  const { userData, onChangeUserData, logOut } = useAuth();

  return useQuery<User, Error>(userQueryKeys.userData, loadMe, {
    retry: 0,
    staleTime: STALE_DATA_TIME,
    enabled: !!token && !userData,
    onSuccess(data) {
      onChangeUserData(data);
    },
    onError() {
      logOut();
    },
  });
};
