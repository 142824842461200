import React, { FC, useEffect } from 'react';
import { Slider } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import {
  SensorSensitivityResponse,
  SensorSensitivityUpdateFormData,
} from 'types/sensor';
import { APIError } from 'types/apiError';
import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';
import {
  fetchSensorSensitivityData,
  updateSensorSensitivity,
} from 'services/API/sensor';
import { STALE_DATA_TIME } from 'utils/constants';
import { toast } from 'react-toastify';
import { getAPIErrorText } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { If } from 'components/If';
import { format } from 'date-fns';
import { queryClient } from 'index';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { TrainingDataPicker } from 'components/TrainingDataPicker';
import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  Popover,
  Button,
  Heading,
  Spinner,
  DataList,
} from '@radix-ui/themes';
import { GearIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';

interface Props {
  id: string;
}

export const SensorSensitivitySettings: FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(1);

  const { mutate: onSubmit } = useMutation<
    SensorSensitivityResponse,
    APIError,
    SensorSensitivityUpdateFormData
  >(updateSensorSensitivity, {
    onSuccess() {
      queryClient.invalidateQueries(machinesQueryKeys.machineHealth);
    },
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  const {
    status,
    data: sensitivityData,
    isLoading,
  } = useQuery<SensorSensitivityResponse, APIError>(
    sensorQueryKeys.sensitivityPath(id),
    () => fetchSensorSensitivityData(id),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  useEffect(() => {
    if (status === 'success') {
      setValue(sensitivityData?.sensitivity || 1);
    }
  }, [status, sensitivityData]);

  const handleChange = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {
    setValue(value as number);
  };

  const handleChangeCommitted = () => {
    onSubmit({
      id,
      sensitivity: value as number,
    });
  };

  const marks = [
    {
      value: 2,
      label: t('modelInformation.low'),
    },
    ...Array.from({ length: 8 }).map((_, i) => ({
      value: i + 2,
    })),
    {
      value: 10,
      label: t('modelInformation.high'),
    },
  ];

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button variant="surface" color="gray" size="2">
          <GearIcon />
          {t('charts.settings')}
        </Button>
      </Popover.Trigger>
      <Popover.Content onOpenAutoFocus={(e) => e.preventDefault()}>
        <Box px="4" py="2">
          <Flex align="center" justify="between" mb="2">
            <Heading size="3">{t('modelInformation.sensitivity')}</Heading>
            <Tooltip content={t('tooltip.sensitivity')}>
              <IconButton variant="ghost" size="1" color="gray" radius="full">
                <QuestionMarkCircledIcon width="20px" height="20px" />
              </IconButton>
            </Tooltip>
          </Flex>

          <If condition={isLoading}>
            <Flex p="3" align="center" justify="center">
              <Spinner />
            </Flex>
          </If>

          <If condition={!isLoading}>
            <Box>
              <Slider
                aria-label="Sensitivity"
                defaultValue={1}
                value={typeof value === 'number' ? value : 1}
                getAriaValueText={(value) => `${value}`}
                valueLabelDisplay="auto"
                step={1}
                marks={marks}
                min={1}
                max={10}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommitted}
              />
            </Box>
            <Heading size="3" my="3">
              {t('modelInformation.training')}
            </Heading>
            <DataList.Root size="2">
              <DataList.Item>
                <DataList.Label>
                  {t('modelInformation.lastTrained')}
                </DataList.Label>
                <DataList.Value>
                  {sensitivityData?.last_training
                    ? format(
                        new Date(sensitivityData.last_training),
                        'd. LLLL yyyy HH:mm'
                      )
                    : t('emptyMessage.default')}
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>
                  {t('modelInformation.trainingTimes')}
                </DataList.Label>
                <DataList.Value>
                  <TrainingDataPicker />
                </DataList.Value>
              </DataList.Item>
            </DataList.Root>
          </If>
        </Box>
      </Popover.Content>
    </Popover.Root>
  );
};
