import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { PasswordAdornment } from 'components/PasswordAdornment';

import { AppRoutes } from 'enums/Routes.enum';
import { useAuth } from 'hooks/Auth/useAuth';
import { APIError } from 'types/apiError';

import {
  microsoftSignIn,
  signIn,
  SignInProps,
  UserData,
} from 'services/API/auth';
import { LocalStorage } from 'services/LocalStorage';

import { patterns, STORE_AUTH_TOKEN } from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';

import logo from 'assets/images/logo.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/icons/microsoft.svg';
import { theme } from 'styles/theme';

interface FormData {
  email: string;
  password: string;
}

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { onChangeUserData, logOut, userData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      navigate(AppRoutes.Dashboard);
    }
  }, [navigate, userData]);

  const { mutate: logIn } = useMutation<UserData, APIError, SignInProps>(
    signIn,
    {
      onSuccess({ token, user }: UserData) {
        onChangeUserData(user);
        LocalStorage.setItem(STORE_AUTH_TOKEN, token);
        navigate(AppRoutes.Dashboard);
      },
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
        logOut();
      },
    }
  );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onMicrosoftLogin = async () => {
    try {
      window.location.href = await microsoftSignIn();
    } catch (error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    }
  };

  const onSubmit = (formData: FormData) => {
    logIn(formData);
  };

  const onEnterKeydownListener = useCallback(
    (e: KeyboardEvent) => {
      const email = getValues('email');
      const password = getValues('password');

      if (e.code === 'Enter' && email && password) {
        logIn({ email, password });
      }
    },
    [getValues, logIn]
  );

  useEffect(() => {
    window.addEventListener('keydown', onEnterKeydownListener);

    return () => {
      window.removeEventListener('keydown', onEnterKeydownListener);
    };
  }, [onEnterKeydownListener, getValues]);

  return (
    <Container
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          minWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '100px',
        }}
      >
        <img src={logo} alt="ai-omatic-app-logo" />
        <Avatar
          alt="lock-icon"
          sx={{
            marginTop: '52px',
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('login.signIn')}
        </Typography>
        <FormGroup
          sx={{
            minWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '120px',
            gap: '10px',
          }}
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('validation.required') || '',
              },
              pattern: {
                value: patterns.email,
                message: t('validation.emailFormatError') || '',
              },
            }}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('login.emailAddress')}
                autoComplete="email"
                autoFocus
                error={!!errors?.email}
                helperText={errors?.email?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('validation.required') || '',
              },
              minLength: {
                value: 6,
                message: t('validation.min', { digits: 6 }),
              },
            }}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                label={t('login.password')}
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                error={!!errors?.password}
                helperText={errors?.password?.message}
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      isPasswordVisible={showPassword}
                      onShowPassword={() => setShowPassword((prev) => !prev)}
                    />
                  ),
                }}
                {...field}
              />
            )}
          />
          <Button
            type="submit"
            onClick={handleSubmit((formData) => onSubmit(formData))}
            fullWidth
            variant="contained"
          >
            {t('login.signIn')}
          </Button>

          <Grid container>
            <Grid item>
              <Link href={AppRoutes.SignUp} variant="body2">
                {t('login.noAccountQuestion')}
              </Link>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              padding: '16px 0',
              color: theme.blue,
            }}
          >
            <Divider
              sx={{
                display: 'flex',
                flex: '1',
                marginRight: '8px',
              }}
            />
            {t('login.or')}
            <Divider
              sx={{
                display: 'flex',
                flex: '1',
                marginLeft: '8px',
              }}
            />
          </Box>

          <Button
            onClick={onMicrosoftLogin}
            fullWidth
            variant="outlined"
            startIcon={<MicrosoftIcon />}
          >
            {t('login.signInWithMicrosoft')}
          </Button>
        </FormGroup>
      </Box>
    </Container>
  );
};
