import { OverallStateData } from 'types/state';

import * as queryString from 'query-string';
import { api } from './client';
import { TimeRangeQuery } from './machine';

export const fetchOverallStateData = async (
  timeRangeQuery: TimeRangeQuery
): Promise<OverallStateData[]> => {
  const query = queryString.stringify(timeRangeQuery);

  const {
    data: { data },
  } = await api.get(`/states/aggregate?${query}`);

  return data;
};

export const fetchMachineOverallStateData: (
  machineId: string,
  timeRangeQuery: TimeRangeQuery
) => Promise<OverallStateData[]> = async (machineId, timeRangeQuery) => {
  const query = queryString.stringify(timeRangeQuery);

  const {
    data: { data },
  } = await api.get(`/states/aggregate/${machineId}?${query}`);

  return data;
};
