import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { tenantsQueryKeys } from 'enums/TenantsQueryKeys.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { getTenants } from 'services/API/tenant';
import { useHasPermission } from 'services/Permissions/usePermissions';
import { APIError } from 'types/apiError';
import { Tenant } from 'types/tenant';
import { STALE_DATA_TIME } from 'utils/constants';

export const useTenants = () => {
  return useQuery<Tenant[], APIError>(tenantsQueryKeys.tenants, getTenants, {
    onError({ response }) {
      toast.error(response?.data.detail);
    },
    staleTime: STALE_DATA_TIME,
    enabled: useHasPermission([
      UserScopes.SelectUserTenant,
      UserScopes.TenantSwitcher,
    ]),
  });
};
