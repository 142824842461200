import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { APIError } from 'types/apiError';
import { STALE_DATA_TIME } from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';
import { MachineTree } from 'types/machine';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { fetchAllMachinesParts } from 'services/API/machine';

export const useMachineTree = () => {
  const { t } = useTranslation();

  return useQuery<MachineTree[], APIError>(
    machinesQueryKeys.allMachines(),
    fetchAllMachinesParts,
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );
};
