import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, TablePagination } from '@mui/material';

import { BasicTable } from 'components/BasicTable';
import { EmptyMessage } from 'components/EmptyMessage';
import { If } from 'components/If';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { AppRoutes } from 'enums/Routes.enum';

import { useTableSorting } from 'hooks/Table/useTableSorting';

import { fetchMachines, MachinesFullData } from 'services/API/machine';

import { APIError } from 'types/apiError';

import {
  REFRESH_MACHINES_TABLE_INTERVAL,
  STALE_DATA_TIME,
} from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';

import { columns } from './columns';

export const MachinesTable: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { sortingOrder, sortingType, onSetSortingType } = useTableSorting();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const options = {
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    order: sortingOrder,
    sort: sortingType,
  };

  const { data: machineTree } = useMachineTree();

  const { data, isLoading } = useQuery<MachinesFullData, APIError>(
    machinesQueryKeys.filteredMachines(options),
    () => fetchMachines(options),
    {
      retry: 0,
      refetchInterval: REFRESH_MACHINES_TABLE_INTERVAL,
      staleTime: STALE_DATA_TIME,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  const itemList = data?.data || machineTree?.slice(0, rowsPerPage) || [];

  return (
    <Box width="100%">
      <BasicTable
        columns={columns}
        data={itemList || []}
        onClickByRow={(item) =>
          navigate(
            generatePath(item.is_sensor ? AppRoutes.Sensor : AppRoutes.Detail, {
              id: item.id,
            })
          )
        }
        onClickByColumnHead={onSetSortingType}
        sortingOrder={sortingOrder}
        sortingType={sortingType}
        loading={isLoading}
        pagination={
          !!data?.meta.count && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              count={data?.meta.count || 0}
              component="div"
              rowsPerPage={rowsPerPage}
              page={isLoading ? 0 : page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )
        }
      />
      <If condition={!isLoading && !data?.data.length}>
        <EmptyMessage message={t('emptyMessage.details')} />
      </If>
    </Box>
  );
};
