export const STORE_KEY_PREFIX = 'ai_omatic_';
export const STORE_AUTH_TOKEN = 'authorize_token';
export const STORE_ENV = 'env';
export const STORE_LANGUAGE = 'language_';
export const CUSTOM_SENSOR_Y_AXIS = 'custom_sensor_y_axis_';
export const STALE_DATA_TIME = 1000 * 60 * 30;
export const REFRESH_MACHINES_TABLE_INTERVAL = 10 * 1000;
export const REFRESH_OVERALL_STATES_INTERVAL = 1000 * 60 * 15;
export const REFRESH_GENERAL_OVERVIEW_FREQUENT = 1000 * 60;
export const REFRESH_SENSOR_DATA_INTERVAL = 1000 * 20;
export const REFRESH_GENERAL_OVERVIEW_DEFAULT = 1000 * 60 * 30;
export const MIN_Y_AXIS = 0;
export const MAX_Y_AXIS = 100;
export const MIDDLE_POINT_IDENTIFIER = 0.0001;
export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
export const EMPTY_STATE = '--';

export const patterns = {
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
};
