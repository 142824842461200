import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AbsoluteSpinner } from 'components/AbsoluteSpinner';
import { AppRoutes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useMe } from 'hooks/Auth/useMe';
import { useMicrosoftMe } from 'hooks/Auth/useMicrosoftMe';
import { LoginPage } from 'views/Auth/LoginPage';
import { ProtectedRoute } from 'views/Auth/ProtectedRoute';
import { DashboardPage } from 'views/Dashboard/DashboardPage';
import { DetailPage } from 'views/Detail/DetailPage/DetailPage';
import { RootComponent } from 'views/Root/RootComponent';
import { SensorPage } from 'views/Sensor/SensorPage';
import { AdministrationPage } from 'views/Administration/AdministrationPage';
import { ModelsPage } from 'views/Lists/Models';
import { Layout } from './components/Layout';

const SignUpPage = lazy(() =>
  import('views/Auth/SignUpPage').then(({ SignUpPage }) => ({
    default: SignUpPage,
  }))
);

function App() {
  const { isLoading } = useMe();
  const { isLoading: isLoadingLogin } = useMicrosoftMe();

  if (isLoading || isLoadingLogin) {
    return <AbsoluteSpinner />;
  }

  return (
    <Routes>
      <Route path={AppRoutes.Root} element={<RootComponent />} />
      <Route path={AppRoutes.Login} element={<LoginPage />} />
      <Route
        path={AppRoutes.SignUp}
        element={
          <Suspense fallback={<AbsoluteSpinner />}>
            <SignUpPage />
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.Dashboard}
        element={
          <ProtectedRoute scopes={[UserScopes.Dashboard]}>
            <Layout>
              <DashboardPage />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoutes.Detail}
        element={
          <ProtectedRoute scopes={[UserScopes.Dashboard]}>
            <Layout>
              <DetailPage />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoutes.Sensor}
        element={
          <ProtectedRoute scopes={[UserScopes.Dashboard]}>
            <Layout>
              <SensorPage />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoutes.ModelsList}
        element={
          <ProtectedRoute scopes={[UserScopes.Dashboard]}>
            <Layout>
              <ModelsPage />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoutes.Administration}
        element={
          <ProtectedRoute
            scopes={[UserScopes.Administration]}
            redirectPath={AppRoutes.Dashboard}
          >
            <Layout>
              <AdministrationPage />
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
