import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { APIError } from 'types/apiError';
import {
  REFRESH_OVERALL_STATES_INTERVAL,
  STALE_DATA_TIME,
} from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';
import { fetchMachinesWidget } from 'services/API/machine';
import { WidgetData } from 'types/machine';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';

export const useGetWidgetData = (machineId?: string) => {
  const { t } = useTranslation();

  return useQuery<WidgetData[], APIError>(
    machinesQueryKeys.machineWidget(machineId || ''),
    async () => {
      return await fetchMachinesWidget({ id: machineId || '' });
    },
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      refetchInterval: REFRESH_OVERALL_STATES_INTERVAL,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );
};
