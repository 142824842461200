import React, { FC } from 'react';

import Box from '@mui/material/Box';
import { WidgetData } from 'types/machine';

interface Props {
  data?: WidgetData[];
}

export const CriticalSensorWidgets: FC<Props> = ({ data }) => {
  return data?.length ? (
    <Box
      sx={{
        wight: '100%',
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateRows: '1fr 1fr 1fr',
        gap: '8px',
        maxHeight: '300px',
      }}
    >
      {data.map(({ label, value, unit }) => (
        <Box
          key={label}
          sx={{
            minWidth: '220px',
            width: '220px',
            height: 'fit-content',
            borderRadius: '4px',
            border: '1px solid',
            borderColor: (theme) => theme.palette.custom.borderColor,
            backgroundColor: (theme) => theme.palette.custom.white,
          }}
        >
          <Box
            sx={{
              padding: '6px 16px',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '157%',
              borderBottom: '1px solid',
              borderColor: (theme) => theme.palette.custom.borderColor,
            }}
          >
            {`${label} (${unit})`}
          </Box>
          <Box
            sx={{
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '18px',
            }}
          >
            {value}
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <div />
  );
};
